import React, { Component } from "react";
import { connect } from 'react-redux';
import {cartService, contentService} from '../_services';
import { PageLoader } from '../PageLoader';

import "../../css/products/products.css";
import {ErrorPage} from "../ErrorPage";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {TestimonialSlider} from "../TestimonialSlider";

const STATIC_IMAGES_PATH = process.env.STATIC_IMAGES_PATH;
const BASE_URL = process.env.API_URL.replace(/api\/v1/,"");

class ProductGroup extends Component {
    render() {
        return (
            <div className="products-group">
                <div className={'item-section-banner row ' + this.props.group.mainColor}>
                    <div className="col-md-12">
                        <span className="group-title">{this.props.group.title}</span>
                        <span className="group-subtitle">{this.props.group.subtitle}</span>
                    </div>
                </div>
                <div className="checkout-options row">
                    <div className="col-md-12">
                        <Link to="/cart"><button className="btn btn-primary-dark checkout-online-button">Checkout Online</button></Link>
                    </div>
                </div>
                <div className="row product-items">
                    {this.props.group.products.map(function(product, i){
                        let classes = "";
                        if (i % 2 === 1) {
                            classes = "bnm-light-gray"
                        }
                        return (
                            <ProductItem product={product} key={product.id} classes={classes} />
                        );
                    })}
                </div>
            </div>
        )
    }
}

class ImageCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeImage: 0,
            totalImages: this.props.images.length
        };
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }

    previousPage() {
        let nextPageNum = this.state.activeImage === 0 ? this.props.images.length - 1 : this.state.activeImage - 1
		this.setState({
                activeImage: nextPageNum
            }
        )
	}

    nextPage() {
        let nextPageNum = this.state.activeImage === this.state.totalImages - 1 ? 0 : this.state.activeImage + 1
		this.setState({
                activeImage: nextPageNum
            }
        )
	}

    render() {
        let activeImage = this.state.activeImage
        let nextPage = this.nextPage
        return (
            <div className="carousel-container">
                {
                    this.props.images.length > 1 &&
                    <div className="carousel-arrow-container" onClick={() => {this.previousPage();}}>
                        <i className="fas fa-chevron-left carousel-arrow" />
                    </div>
                }
                {this.props.images.map(function(image, i){
                    let activeImageClass = i === activeImage ? "active" : ""
                    return (
                        <img className={"product-image " + activeImageClass} src={STATIC_IMAGES_PATH + image.filepath} key={image.id} onClick={() => {nextPage();}}/>
                    );
                })}
                {
                    this.props.images.length > 1 &&
                    <div className="carousel-arrow-container" onClick={() => {this.nextPage();}}>
                        <i className="fas fa-chevron-right carousel-arrow" />
                    </div>
                }
            </div>
        )
    }
}

class ProductItem extends Component {
    addToCart(item) {
        cartService.increaseItemQuantity(item)
            .then(response => {
                toast.success(item.title + ' added to cart!', {
                  icon: false
                });
            }, error => {
                toast.error('Error adding item to cart. Please refresh the page and try again.');
            });
    }

    render() {
        return (
            <div className={'products-item ' + this.props.classes}>
                <div className="item-content row">
                    <div className="col-md-4">
                        <ImageCarousel images={this.props.product.images} />
                    </div>
                    <div className="col-md-8">
                        <span className="item-product-title">{this.props.product.title}</span>
                        <span className="item-product-subtitle">{this.props.product.subtitle}</span>
                        <span className="item-product-price">${this.props.product.price}</span>
                        <span className="item-description" dangerouslySetInnerHTML={{__html: this.props.product.description}} />
                        <button className="btn btn-primary-dark cart-button" onClick={() => {
                            this.addToCart(this.props.product);
                        }}>Add to Cart</button>
                    </div>
                </div>
            </div>
        )
    }
}

class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestFailed: false
        };
    }

    componentDidMount() {
        contentService.getPageContent('product-groups')
            .then(response => {
                this.setState({
                    productGroups: response.product_groups,
                    testimonials: response.testimonials,
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.productGroups) {
            return (
                <PageLoader />
            )
        }

        return (
            <div className="products-content">
                <TestimonialSlider testimonials={this.state.testimonials}/>
                <div className="row product-groups">
                    {this.state.productGroups.map(function(group, i){
                        return (
                            <ProductGroup group={group} key={group.id} />
                        );
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedProductPage = connect(mapStateToProps)(ProductPage);
export { connectedProductPage as ProductPage };