import React, {Component} from 'react';
import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import {cartService, orderService} from "../_services";
import {history} from "../_helpers";
import {Link} from "react-router-dom";
import "regenerator-runtime/runtime";

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCheckoutError: false,
            checkoutErrorMessage: 'Error placing order. Please refresh and try again.',
            submitted: false,
            submittedAddress: false,
            cardName: '',
            fullName: '',
            streetAddress: '',
            streetAddress2: '',
            city: '',
            stateCode: '',
            zipCode: '',
            processing: false,
            processingAddress: false,
            addressSubmitted: false,
        };
        this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.editShippingAddress = this.editShippingAddress.bind(this);
        this.submitShippingAddress = this.submitShippingAddress.bind(this);
    }

    async handlePlaceOrder(ev) {
        ev.preventDefault();
        this.setState({
            submitted: true,
            processing: true,
            showCheckoutError: false
        });
        const { cardName, fullName, streetAddress, streetAddress2, city, stateCode, zipCode } = this.state;
        const shippingAddress = {
            name: fullName,
            street: streetAddress + " " + streetAddress2,
            city: city,
            state: stateCode,
            zipcode: zipCode
        };

        if (this.props.shippedItemsInOrder && (!fullName || !streetAddress || !city || !stateCode || !zipCode)) {
            this.setState({ processing: false })
            return;
        }

        const cardElement = this.props.elements.getElement(CardElement);
        let {token} = await this.props.stripe.createToken(cardElement);
        if (!cardName || !token) {
            this.setState({ processing: false })
            return;
        }

        let products = [];
        this.props.cartItems.map(function(item, ix){
            for (let i = 0; i < item.quantity; i++) {
                products.push(item.id)
            }
        });
        let price = this.props.calculatePrice(this.props.cartItems);
        orderService.placeOrder(products, price, token.id, cardName, shippingAddress).then(
            order => {
                cartService.clearCart();
                history.push('/receipt/' + order.confirmation_code);
            },
            error => {
                this.setState({
                    showCheckoutError: true,
                    checkoutErrorMessage: 'Error placing order.',
                    processing: false
                })
            }
        )
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    editShippingAddress() {
		this.setState({
                addressSubmitted: false,
            }
        )
	}

	submitShippingAddress() {
        this.setState({
            submittedAddress: true,
            processingAddress: true
        });
        const { fullName, streetAddress, city, stateCode, zipCode } = this.state;
        if (this.props.shippedItemsInOrder && (!fullName || !streetAddress || !city || !stateCode || !zipCode)) {
            this.setState({
                processingAddress: false
            });
            return;
        }
        this.setState({
            addressSubmitted: true,
            processingAddress: false
        });
    }

    render() {
        const hideShippingAddress = this.props.shippedItemsInOrder ? '' : 'hide-element';
        const hideShippingAddressEdit = this.state.addressSubmitted ? '' : 'hide-element';
        const showShippingAddressSubmit = this.state.addressSubmitted || !this.props.shippedItemsInOrder ? 'hide-element' : '';
        const hidePaymentDetails = this.props.shippedItemsInOrder && !this.state.addressSubmitted ? 'hide-element' : '';
        const hideCheckoutError = this.state.showCheckoutError ? '' : 'hide-element';
        const hideSpinner = this.state.processing ? '' : 'hide-element';
        const hideAddressSpinner = this.state.processingAddress ? '' : 'hide-element';
        const { submitted, submittedAddress, cardName, fullName, streetAddress, streetAddress2, city, stateCode, zipCode } = this.state;

        return (
            <div className="checkout">
                <div className={ hideShippingAddress }>
                    <span className="checkout-billing-title checkout-shipping-address-title">Shipping address<a className={"shipping-address-edit " + hideShippingAddressEdit} onClick={this.editShippingAddress}>edit</a></span>

                    <div className={'form-group' + (submittedAddress && !fullName ? ' has-error' : '')}>
                        <label className="checkout-field-label" htmlFor="fullName">Full name</label>
                        <input disabled={this.state.addressSubmitted} type="text" className="form-control checkout-field-input" name="fullName" value={fullName} onChange={this.handleChange}/>
                    </div>

                    <div className={'form-group' + (submittedAddress && !streetAddress ? ' has-error' : '')}>
                        <label className="checkout-field-label" htmlFor="streetAddress">Street Address</label>
                        <input disabled={this.state.addressSubmitted} type="text" className="form-control checkout-field-input" name="streetAddress" value={streetAddress} onChange={this.handleChange}/>
                    </div>

                    <div className="form-group">
                        <label className="checkout-field-label optional" htmlFor="streetAddress2">Street Address (line 2)</label>
                        <input disabled={this.state.addressSubmitted} type="text" className="form-control checkout-field-input" name="streetAddress2" value={streetAddress2} onChange={this.handleChange}/>
                    </div>

                    <div className="row city-state-zip-row">
                        <div className="col-md-4 checkout-field-city">
                            <div className={'form-group' + (submittedAddress && !city ? ' has-error' : '')}>
                                <label className="checkout-field-label" htmlFor="city">City</label>
                                <input disabled={this.state.addressSubmitted} type="text" className="form-control checkout-field-input" name="city" value={city} onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={'form-group' + (submittedAddress && !stateCode ? ' has-error' : '')}>
                                <label className="checkout-field-label" htmlFor="stateCode">State</label>
                                <select disabled={this.state.addressSubmitted} className="form-control" id="shipping-state" name="stateCode" value={stateCode} onChange={this.handleChange}>
                                    <option>----</option>
                                    <option value="AL">AL</option>
                                    <option value="AK">AK</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MO">MO</option>
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="NC">NC</option>
                                    <option value="NE">NE</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="ND">ND</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VT">VT</option>
                                    <option value="VA">VA</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 checkout-field-zip">
                            <div className={'form-group' + (submittedAddress && !zipCode ? ' has-error' : '')}>
                                <label className="checkout-field-label" htmlFor="zipCode">ZIP Code</label>
                                <input disabled={this.state.addressSubmitted} type="text" className="form-control checkout-field-input" name="zipCode" value={zipCode} onChange={this.handleChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ hidePaymentDetails }>
                    <span className="checkout-billing-title">Payment details</span>
                    <CardElement className="checkout-field-input form-control"/>
                    <div className={'form-group' + (submitted && !cardName ? ' has-error' : '')}>
                        <label className="checkout-field-label" htmlFor="cardName">Name on card</label>
                        <input type="text" className="form-control checkout-field-input" name="cardName" value={cardName} onChange={this.handleChange}/>
                    </div>
                </div>

                <div className={"place-order-container " + hidePaymentDetails}>
                    <div className={ 'error-box ' + hideCheckoutError }>
                        <i className="fas fa-exclamation-circle" />
                        <span>{this.state.checkoutErrorMessage}</span>
                    </div>

                    <div className="checkout-order-button">
                        <button className="btn btn-primary-dark" onClick={this.handlePlaceOrder} disabled={this.state.processing}>Place Order <i className={"fa fa-spinner fa-spin " + hideSpinner}/></button>
                    </div>
                    <span className="checkout-terms">By purchasing, you agree to the Because Neatness Matters <Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link></span>
                </div>
                <div className={"submit-address-container " + showShippingAddressSubmit}>
                    <div className="submit-address-button">
                        <button className="btn btn-primary-dark" onClick={this.submitShippingAddress} disabled={this.state.processingAddress}>Use This Address <i className={"fa fa-spinner fa-spin " + hideAddressSpinner}/></button>
                    </div>
                </div>
            </div>
        );
    }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm stripe={stripe} elements={elements} cartItems={props.cartItems} calculatePrice={props.calculatePrice} handleShow={props.handleShow} shippedItemsInOrder={props.shippedItemsInOrder} />
      )}
    </ElementsConsumer>
  )
}
