import {authHeader} from "../_helpers";

const API_BASE = process.env.API_URL;

export const orderService = {
    getOrder,
    placeOrder,
};

function getOrder(confirmationCode) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(API_BASE + '/orders/' + confirmationCode, requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 403) {
                    return Promise.reject({
                        "message": "You don't have permission to view this page.",
                        "iconClasses": "fa fa-ban",
                    });
                }
                return Promise.reject({
                    "message": "Uh oh! Looks like we're having trouble loading this page."
                });
            }

            return response.json();
        });
}

function placeOrder(products, price, stripe_token, card_name, shipping_address) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ products, price, stripe_token, card_name, shipping_address})
    };

    return fetch(API_BASE + '/checkout', requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(json => {
                    let message = json.message ? json.message : 'Please refresh and try again.';
                    return Promise.reject(message);
                });
            }
            else {
                return response.json();
            }
        });
}
