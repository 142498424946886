import React, { Component } from "react";
import CheckoutForm from './CheckoutForm'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { cartService } from '../_services';
import { PageLoader } from '../PageLoader';

import "../../css/checkout/checkout.css";
import {history} from "../_helpers";
import {ErrorPage} from "../ErrorPage";

const BASE_URL = process.env.API_URL.replace(/api\/v1/,"");
const FREE_SHIPPING_THRESHOLD_DOLLARS = 500.0;

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);


class CartSummaryItem extends Component {
    render() {
        return (
            <div className="cart-summary-item row">
                <div className="col-md-9">
                    <span className="cart-summary-item-title">{this.props.item.quantity} x {this.props.item.title}</span>
                    <span className="cart-summary-item-subtitle">{this.props.item.subtitle}</span>
                </div>
                <div className="col-md-3">
                    <span className="cart-summary-item-price">${(this.props.item.price * this.props.item.quantity).toFixed(2)}</span>
                </div>
            </div>
        )
    }
}


class CheckoutPage extends Component {
    constructor(props) {
        super(props);
        this.props.updateUserAuthenticationState();

        this.state = {
            hideShippingAddress: true,
            salesTax: 0.00
        };
        this.setSalesTax = this.setSalesTax.bind(this);
        this.calculatePrice = this.calculatePrice.bind(this);
    }

    componentDidMount() {
        cartService.getCartItems()
            .then(cartItems => {
                cartItems = cartService.attachCartQuantities(cartItems);
                if (cartItems.length === 0) {
                    history.push('/products');
                }
                this.setState({
                    cartItems: cartItems,
                    bundledCartItems: cartService.extractBundledCartItems(cartItems)
                })
            }, (error) => {
                this.setState({
                    requestFailed: true,
                    errorMessage: error
                })
            });
    }

    calculatePrice(cartItems) {
        let price = 0.0;
        let shippingPrice = 0.0;
        {cartItems.map(function(item, i){
            if (item.category === 'physical') {
                shippingPrice += item.price * item.quantity;
            }
            price += item.price * item.quantity;
        })}
        if (price < FREE_SHIPPING_THRESHOLD_DOLLARS) {
            price += (shippingPrice * 0.18); // 18% shipping and handling charge
        }
        return parseFloat(price + this.state.salesTax).toFixed(2);
    }

    cartContainsShippedProducts(cartItems) {
        let result = false;
        {cartItems.map(function(item, i){
            if (item.category === 'physical') {
                result =  true;
            }
        })}
        return result;
    }

    getShippingPrice(cartItems) {
        let totalPrice = 0.0;
        let shippingPrice = 0.0;
        {cartItems.map(function(item, i){
            totalPrice += item.price * item.quantity;
            if (item.category === 'physical') {
                shippingPrice += item.price * item.quantity;
            }
        })}
        if (totalPrice >= FREE_SHIPPING_THRESHOLD_DOLLARS) {
            return 0.00;
        }
        return (shippingPrice * 0.18).toFixed(2); // 18% shipping and handling charge
    }

    setSalesTax(salesTax) {
        this.setState({
            salesTax: salesTax
        })
    }

    render() {
        if (this.state.requestFailed) {
            return (
                <ErrorPage errorMessage={this.state.errorMessage}/>
            )
        }
        if (!this.state.cartItems) {
            return (
                <PageLoader />
            )
        }

        let shippingPrice = this.getShippingPrice(this.state.cartItems);
        let totalPrice = this.calculatePrice(this.state.cartItems);
        let shippedItemsInOrder = this.cartContainsShippedProducts(this.state.cartItems) || this.cartContainsShippedProducts(this.state.bundledCartItems);
        let showShippingCosts = shippingPrice > 0 ? '' : 'hide-element';

        return (
            <div className="">
                <div className="checkout-container container">
                    <div className="checkout-content row">
                        <div className="col-md-6 col-md-push-6">
                            <div className="checkout-cart-summary well">
                                <span className="cart-summary-title">Cart Summary<span className="checkout-cart-edit"><Link to="/cart">edit</Link></span></span>
                                {this.state.cartItems.map(function(item, i){
                                    return (
                                        <CartSummaryItem item={item} key={item.id} />
                                    );
                                })}
                                {this.state.bundledCartItems.map(function(bundledItem, i){
                                    return (
                                        <CartSummaryItem item={bundledItem} key={bundledItem.id} />
                                    );
                                })}
                                <div className={"shipping-cost-item row " + showShippingCosts}>
                                    <div className="col-md-9">
                                        <span className="cart-summary-item-title">Shipping & Handling</span>
                                    </div>
                                    <div className="col-md-3">
                                        <span className="cart-summary-item-price">${shippingPrice}</span>
                                    </div>
                                </div>
                                <div className={"taxes-item row"}>
                                    <div className="col-md-9">
                                        <span className="cart-summary-item-title">Sales Tax</span>
                                    </div>
                                    <div className="col-md-3">
                                        <span className="cart-summary-item-price">${this.state.salesTax.toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className="checkout-cart-summary-subtotal">
                                    <span className="checkout-cart-summary-subtotal-label">Total</span>
                                    <span className="checkout-cart-summary-price">${totalPrice}</span>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-billing col-md-6 col-md-pull-6">
                            <div className="checkout-form">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm cartItems={this.state.cartItems} salesTax={this.state.salesTax} calculatePrice={this.calculatePrice} setSalesTax={this.setSalesTax} handleShow={this.handleShow} shippedItemsInOrder={shippedItemsInOrder} />
                                </Elements>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedCheckoutPage = connect(mapStateToProps)(CheckoutPage);
export { connectedCheckoutPage as CheckoutPage };